import type { ForwardedRef } from 'react';
import type { NavLinkProps } from 'react-router-dom';

import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

type AnchorLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  activeClassName?: NavLinkProps['activeClassName'];
  to?: NavLinkProps['to'];
};

/**
 * Renders an HTML anchor element `<a>` by default. If the `to` prop is
 * provided, a `<NavLink>` element by React Router is rendered instead. This
 * will navigate to the specified route path using React Router instead.
 *
 * Use this when you want to conditionally render either an HTML link element or
 * a React Router link.
 */
function SmartLink(
  { activeClassName, children, className, style, to, ...props }: AnchorLinkProps | NavLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  if (to) {
    return (
      <NavLink ref={ref} activeClassName={activeClassName} className={className} style={style} to={to} {...props}>
        {children}
      </NavLink>
    );
  }

  if (typeof className === 'function' || typeof style === 'function') {
    throw new TypeError(
      'SmartLink: Did you forget a "to" field? If not, check "className" or "style" types and make sure they\'re correct.',
    );
  }

  return (
    <a ref={ref} className={className} style={style} {...props}>
      {children}
    </a>
  );
}

export default React.memo(forwardRef(SmartLink));
